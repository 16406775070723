import React from "react";
import Accordion from "../../Accordion";
import Header, { HeaderVariant } from "../../Header";

interface QuestionsProps {
  title: string;
  data: { 
    title: string; 
    content: string 
  }[];
}

const Questions: React.FC<QuestionsProps> = props => {
  const { title, data } = props;

  return (
    <div className="py-10 sm:py-16 mx-auto">
      <Header
        className="text-gray-3-dark text-center"
        variant={HeaderVariant.h2}
      >
        {title}
      </Header>
      <div className="mx-8 sm:mx-auto max-w-xl lg:max-w-4xl flex flex-col overflow-hidden clear-both justify-center mt-14">
        {data?.map((item, index) => {
          return (
            <div className="w-full">
              <Accordion
                key={index}
                title={item?.title}
                maxContentHeight={"max-h-[310px]"}
                content={item?.content}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Questions;
