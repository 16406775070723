import { graphql } from "gatsby";
import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/Seo";
import "../components/VideoAdsPage/video-ads-page.scss";
import Layout from "../containers/Layout";
import HeroSection from "../components/HeroSection";
import { IconType } from "../components/Icon";
import GradientSection from "../containers/GradientSection";
import BottomCTA from "../components/BottomCta";
import ReferSeller from "../components/ReferralPage/ReferSeller";
import { useTranslation } from "gatsby-plugin-react-i18next";
import GettingStarted from "../components/ReferralPage/GettingStarted";
import Questions from "../components/ReferralPage/Questions";
import { PAGE } from "../constants/pages";

interface ReferralProps {
  data: any;
}

const Referral: React.FC<ReferralProps> = ({ data }) => {
  const pageData = data;
  const { t } = useTranslation();

  const questions = [
    {
      title: t("How do I create a link?"),
      content: t(
        "Create your unique sharing link by entering your email address and clicking the Create link button. /n Copy your link, by clicking the Copy button, and send it to anyone you’d like to refer."
      )
    },
    {
      title: t("Who can I refer?"),
      content: t(
        "You can refer any Amazon Seller who is actively advertising on Amazon. /n our referrals are considered “actively advertising on Amazon” if they have spent at least $100 on Amazon PPC the month before requesting the Perpetua Benchmarker. /n All eligible referrals can result in the following rewards: /n • $100 USD if they request a free Perpetua Benchmarker report /n • 10% lifetime commission (plus the option for a 10% renewal bonus) if they become a Perpetua customer after you refer them"
      )
    },
    {
      title: t("How do I claim my rewards?"),
      content: t(
        "When you claim your link, you should also receive an email from us discussing next steps. We work closely with a software vendor called PartnerStack to track referrals and automate the program rewards. If you become eligible for a reward, you will receive an email from PartnerStack, prompting you to “Claim your commission.” /n Once you create a profile, you will be able to access your PartnerStack dashboard. Here you’ll see the rewards that you qualify for and can select your preferred payment method: PayPal or Stripe."
      )
    },
    {
      title: t("How do I use partnerstack?"),
      content: t(
        "The PartnerStack dashboard is quite user-friendly and intuitive. Once you’re eligible for a reward and are prompted to create your profile via email, reference this helpful article from PartnerStack on exactly how to get paid. /n You can also reach out to support@partnerstack.com if you have any additional questions about the dashboard."
      )
    }
  ];

  const heroCta = {
    text: "Create Link",
    route: "/"
  };

  return (
    <Layout>
      <SEO
        title={t("Perpetua Benchmarker Referral Program")}
        description={t(
          "We'll give you a personal link to share via text, email, and social media, then reward you for every eligible seller that requests a report."
        )}
        image={`https://perpetua.io${pageData?.openGraphImage?.publicURL}`}
      />
      <HeroSection
        page={PAGE.REFERRAL}
        bgGradient="bg-white"
        gradientTitle={[t("Start earning"), t("with Perpetua")]}
        gradientPosition={1}
        lineBreak={true}
        h1Title={t("Referral Program")}
        description={t(
          "We’ll give you a personal link to share via text, email, and social media, then reward you $100 for every eligible seller that requests a report."
        )}
        icon={IconType.team}
        cta={heroCta}
        imageReplacement={
          <StaticImage
            src="../images/referral/hero-img.png"
            alt="Perpetua Referral Program"
            className="w-full md:h-full h-1/2"
          />
        }
      />
      <GradientSection className="sm:mb-12 lg:mt-28 md:mb-0 rounded-tr-[4rem]  sm:rounded-tr-large bg-gradient-to-tr from-white via-white to-indigo-3-light">
        <ReferSeller
          title={t(
            "Earn $100 when you refer-a-seller to the Perpetua Benchmarker"
          )}
          description={t(
            "We’ll give you a personal link to share via text, email, and social media, then reward you $100 for every eligible seller that requests a report."
          )}
        />
      </GradientSection>
      <GradientSection className="bg-gradient-to-b from-white via-white to-indigo-3-light rounded-bl-large mb-12 pb-12">
        <GettingStarted
          gradientTitle={[t("Getting started"), t("is easy")]}
          gradientPosition={2}
        />
      </GradientSection>

      <Questions title={t("Still have Questions?")} data={questions} />

      <BottomCTA
        primaryButtonId={""}
        secondaryButtonId={""}
        title={t("Ready to start earning?")}
        subtitle={t(
          "For more information or to inquire about the status of your referral(s), please reach out to the Perpetua Marketing team at accessbenchmarker@perpetua.io."
        )}
        primaryButtonText={t("Create Link")}
        primaryButtonOnClick={() => {
          window.scrollTo({
            top: 80,
            left: 0,
            behavior: "smooth"
          });
        }}
        secondaryButtonText={t("Have a question?")}
        secondaryButtonOnClick={() =>
          (window.location.href = "mailto:accessbenchmarker@perpetua.io")
        }
      />
    </Layout>
  );
};

export default Referral;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    openGraphImage: file(
      relativePath: { eq: "referral/referral-ogImage.png" }
    ) {
      publicURL
    }
  }
`;
