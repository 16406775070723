import { StaticImage } from "gatsby-plugin-image";
import React from "react";

interface ReferSellerProps {
  title: string;
  description: string;
}

const ReferSeller: React.FC<ReferSellerProps> = props => {
  const { title, description } = props;

  return (
    <div className="pt-18 sm:pt-16 grid grid-cols-1 lg:grid-cols-2 items-center text-center lg:text-left">
      <div className="order-2 lg:order-1 ml-7 sm:ml-32 lg:ml-0">
        <StaticImage  
          src="../../../images/referral/refer-seller.png"
          alt=""
          className="w-full h-full"
        />
      </div>
      <div className="max-w-4xl order-1 lg:order-2 mx-4 sm:mx-12 lg:mx-0">
        <p className="text-3xl sm:text-4xl font-semibold mb-6 max-w-[280px] sm:max-w-full mx-auto">{title}</p>
        <p className="lg:max-w-xl">{description}</p>
      </div>
    </div>
  );
};

export default ReferSeller;
