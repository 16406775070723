import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import GradientTitle from "../../GradientTitle";
import { HeaderVariant } from "../../Header";
import CreateLink from "../CreateLink";
import RightLine from "../../../images/referral/right-line.svg"
import LeftLine from "../../../images/referral/left-line.svg"

interface GettingStartedProps {
  gradientTitle: string[];
  gradientPosition: number;
}

const GettingStarted: React.FC<GettingStartedProps> = props => {
  const { gradientTitle, gradientPosition } = props;

  return (
    <div className="sm:px-4 mx-auto">
      <div className="text-center pt-20">
        <div className="w-full mx-auto lg:max-w-4xl">
          <GradientTitle
            gradientPosition={gradientPosition}
            className="text-4xl font-semibold mb-6 md:text-5xl md:font-bold md:mb-8 mx-auto"
            color="purple"
            variant={HeaderVariant.h3}
            title={gradientTitle}
            lineBreak={false}
          />
        </div>
        <div className="flex flex-col items-center sm:mt-20 lg:mt-0 relative">
          <div className="z-10 flex flex-col text-center items-center max-w-lg">
            <span className="bg-indigo-2 z-10 w-16 h-16 rounded-full text-white text-3xl font-bold flex justify-center items-center">
              1
            </span>
            <GradientTitle
              gradientPosition={2}
              className="my-5"
              color="purple"
              variant={HeaderVariant.h4}
              title={["Create", "your link"]}
              lineBreak={false}
            />
            <p className="mb-6 sm:mb-0">
              Enter your email address and click the the button and we’ll
              generate a personal sharing link for you.
            </p>
            <CreateLink />
          </div>
          <RightLine className="w-[46%] h-[35%] mt-8 absolute right-0 top-[-5px] hidden xl:block" />
          <div className="flex flex-col lg:flex-row w-full justify-around items-center mt-32 mb-28">
            <div className="flex flex-col text-center items-center max-w-xl order-2 lg:order-1 mt-8 lg:mt-0 z-10">
              <StaticImage
                src="../../../images/referral/mail.png"
                alt="Create your link"
              />
            </div>
            <div className="flex flex-col text-center items-center max-w-lg order-1 lg:order-2">
              <span className="bg-indigo-2 z-10 w-16 h-16 rounded-full text-white text-3xl font-bold flex justify-center items-center">
                2
              </span>
              <GradientTitle
                gradientPosition={1}
                className="my-5"
                color="purple"
                variant={HeaderVariant.h4}
                title={["Convieniently", "share your network"]}
                lineBreak={false}
              />
              <p>
                Copy and send your link to as many Amazon Sellers in your
                network with a simple text message, email or via social media.
              </p>
            </div>
          </div>
          <LeftLine className="w-[57%] h-[43%] mt-8 absolute left-[15%] top-[29%] hidden xl:block" />
          <div className="flex flex-col text-center items-center max-w-lg">
            <span className="bg-indigo-2 z-10 w-16 h-16 rounded-full text-white text-3xl font-bold flex justify-center items-center">
              3
            </span>
            <GradientTitle
              gradientPosition={2}
              className="my-5"
              color="purple"
              variant={HeaderVariant.h4}
              title={["Earn", "actual cash"]}
              lineBreak={false}
            />
            <p>
              For each Perpetua Benchmarker sign up from your link, we’ll
              automatically pay you.
            </p>
          </div>
          <div className="flex flex-col lg:flex-row w-full items-center justify-center gap-10 my-18">
            <div className="bg-white py-14 px-8 rounded-2xl drop-shadow-lg w-72 sm:w-96 min-h-[220px] flex flex-col justify-around">
              <p className="text-5xl font-bold">
                $100 <small className="text-base font-semibold">USD</small>
              </p>
              <p className="text-gray-2 text-base">
                per each Amazon advertiser with at least $100/month in ad spend
              </p>
            </div>
            <div className="bg-white py-14 px-8 rounded-2xl drop-shadow-lg w-72 sm:w-96 min-h-[220px] flex flex-col justify-around">
              <p className="text-5xl font-bold">10%
                <small className="text-base font-semibold ml-4">Commission</small>
              </p>
              <p className="text-gray-2 text-base">
                if they become a Perpetua customer
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStarted;
