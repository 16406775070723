import React from "react";
import Button, { ButtonVariant } from "../../Button";
import axios from "axios";
import { validateEmail } from "../../../utils/email";

const CreateLink: React.FC<{}> = props => {
  const [email, setEmail] = React.useState<string>("");
  const [buttonText, setButtonText] = React.useState<string>("Create Link");
  const [linkCreated, setLinkCreated] = React.useState<boolean>(false);
  const [inputError, setInputError] = React.useState<boolean>(false);

  const generateReferralLink = () => {
    if (validateEmail(email)) {
      setInputError(false);
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      axios
        .post(
          `https://api.partnerstack.com/v1/partnerships/anonymous/sellics/${email}`,
          "",
          config
        )
        .then(res => {
          setEmail(JSON.parse(res?.data?.rdata)?.link);
          setLinkCreated(true);
          setButtonText("Copy");
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setInputError(true);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email);
    setButtonText("Copied!");
  };

  return (
    <div className="sm:pt-10 max-w-lg items-center w-full text-left">
      <div className="flex flex-col sm:flex-row">
        <input
          className="border border-gray-3 rounded-lg focus:border-indigo-1 sm:max-w-xs focus:outline-none focus:ring-4 focus:ring-indigo-3-light py-2 px-4 w-full text-base"
          placeholder={linkCreated ? "Copy" : "Your Email"}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Button
          className="w-full sm:max-w-xs sm:w-28 sm:ml-4 mt-3 sm:mt-0"
          variant={ButtonVariant.primary}
          text={buttonText}
          onClick={() => {
            linkCreated ? copyToClipboard() : generateReferralLink();
          }}
        />
      </div>

      {inputError && (
        <small className="text-center mt-2 text-red-2-light font-medium">
          Oops! You must enter a valid email address.
        </small>
      )}
    </div>
  );
};

export default CreateLink;
